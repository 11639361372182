<template>
  <div v-if="isShow" class="d-flex justify-center items-center touch-tip" @click="isShow = false">
    <div class="flex-column items-center touch-tip__circle">
      <div class="touch-tip__hand"></div>
      <p
        class="touch-tip__text"
        :class="{'font-bold touch-tip__text--en': lang !== 'hk'}"
      >
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import lottie from 'lottie-web'
import hand from '@/assets/lottie/hand_white.json'

export default {
  name: 'TouchTip',
  data () {
    return {
      isShow: true
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    text () {
      return this.lang === 'hk' ? '觸碰探索佈置靈感' : 'Touch to explore inspirations'
    }
  },
  mounted () {
    this.$nextTick(() => {
      lottie.loadAnimation({
        container: document.querySelector('.touch-tip__hand'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: hand
      })

      setTimeout(() => {
        this.isShow = false
      }, 3000)
    })
  }
}
</script>

<style lang="scss" scoped>
.touch-tip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 888;

  &__circle {
    width: 20.78125vw;
    height: 20.78125vw;
    background-color: rgba($color: $black, $alpha: 0.8);
    border-radius: 50%;
    padding-top: 1.852vh;
  }

  &__hand {
    width: 11.46vw;
  }

  &__text {
    font-size: 2.03125vw;
    line-height: 1.38;
    color: $white;
    width: 8.125vw;
    text-shadow: 0 3px 30px rgba(0, 0, 0, 0.6);

    &--en {
      text-align: center;
      width: 17.771vw;
    }
  }
}
</style>
