<template>
  <div class="inspiration">
    <div class="d-flex inspiration__space-container">
      <div
        v-for="item in list"
        :key="item.id"
        class="d-flex items-center justify-between inspiration__space"
        :class="{'inspiration__space--en': lang !== 'hk'}"
        @click="onSpaceClick(item.id)"
      >
        <img :src="item.pic">
        <p class="font-bold inspiration__space-text" :class="{'inspiration__space-text--en': lang !== 'hk'}">{{ item.name }}</p>
      </div>
    </div>
    <TouchTip />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TouchTip from '@/components/common/TouchTip'

export default {
  name: 'Inspiration',
  components: {
    TouchTip
  },
  computed: {
    ...mapState('inspiration', ['space', 'space_en']),
    ...mapState('lang', ['lang']),
    list () {
      return this.lang === 'hk' ? this.space : this.space_en
    }
  },
  created () {
    this.fetchShowroomType()
    this.fetchBigScreenPhase({ current: '01', current_id: '', lang: this.lang })
  },
  methods: {
    ...mapActions('api', ['fetchShowroomType', 'fetchBigScreenPhase']),
    ...mapActions('gtagEvents', ['roomsClickTrack']),
    onSpaceClick (id) {
      const name = this.space_en.find(item => item.id === id).name
      this.roomsClickTrack({ room: name, lan: this.lang })
      this.$router.push({
        name: 'Category',
        params: {
          id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.inspiration {
  padding: 0 8.3333vw;
  height: 71.111vh;

  &__space-container {
    flex-wrap: wrap;
    height: 100%;
  }

  &__space {
    position: relative;
    flex: 0 0 33%;
    padding-left: 3.646vw;
    padding-right: 2.604vw;

    &--en {
      padding-right: 4.16667vw;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 1.875vw);
      height: 0.27778vh;
      background: rgba($color: $black, $alpha: 0.5);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0.27778vh;
      height: calc(100% - 2.2222vh);
      background: rgba($color: $black, $alpha: 0.5);
    }

    &:nth-child(3n+1) {
      &::before {
        top: 2.2222vh;
        height: calc(100% - 4.4444vh);
      }
    }

    &:nth-child(7) {
      &::before {
        height: calc(100% - 2.2222vh);
      }

      &::after {
        display: none;
      }
    }

    &:nth-child(3n+2) {
      &::before {
        top: 2.2222vh;
        height: calc(100% - 4.4444vh);
      }
      &::after {
        left: 1.875vw;
        width: calc(100% - 3.75vw);
      }
    }

    &:nth-child(8) {
      &::before {
        height: calc(100% - 2.2222vh);
      }

      &::after {
        display: none;
      }
    }

    &:nth-child(3n+3) {
      &::before {
        display: none;
      }
      &::after {
        left: 1.875vw;
      }
    }

    &:nth-child(9) {
      &::after {
        display: none;
      }
    }
  }

  &__space-text {
    text-align: center;
    width: 8.02vw;
    font-size: 2.08333vw;
    letter-spacing: -0.0625vw;

    &--en {
      text-align: left;
      line-height: 1.4;
    }
  }
}
</style>
